/** @jsx jsx */
import { Video } from '@sprinklr/shared-lib';
import { jsx } from 'theme-ui';
import { GatsbyImage } from 'gatsby-plugin-image';

const ShowcaseTabsMedia = props => {
  const { videoPosterImage, video, media } = props;

  if (videoPosterImage && video) {
    return (
      <Video
        poster={videoPosterImage.file.url}
        source={video.file.url}
        variant={'lg'}
      />
    );
  }

  return (
    <GatsbyImage
      image={media}
      sx={{
        boxShadow: [0, 1],
        borderRadius: [1, 3],
        maxHeight: '450px',
      }}
      fadeIn={false}
    />
  );
};

export default ShowcaseTabsMedia;
