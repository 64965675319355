/** @jsx jsx */
import { jsx, Flex, Select, Box } from 'theme-ui';
import { Image, CTA } from '@sprinklr/shared-lib/@types/entities';
import { Themed } from 'theme-ui';
import { useState, useCallback, ChangeEvent } from 'react';
import ChevronDown from '@sprinklr/shared-lib/assets/svgs/chevron-down.svg';
import { CallToAction } from '@sprinklr/shared-lib';
import { Container } from '@sprinklr/shared-lib';
import ShowcaseTabsMedia from './ShowcaseTabsMedia';

type ShowcaseTabsItem = {
  title: string;
  caption: string;
  media: Image;
  ctAs?: CTA[];
};

type ShowcaseTabsProps = {
  sectionId?: string;
  heading: { heading: string };
  description?: { description: string };
  imageAlignment: 'LEFT' | 'RIGHT';
  items: any; //Raw JSON, moved .map inside, was ShowcaseTabsItem[],
};

const ShowcaseTabs: React.FC<ShowcaseTabsProps> = ({
  sectionId,
  imageAlignment = 'RIGHT',
  ...props
}) => {
  const heading = props?.heading?.heading;
  const description = props?.description?.description;
  const items = props?.items?.map(item => ({
    title: item.title,
    caption: item.caption.caption,
    video: item.video,
    videoPosterImage: item.videoPosterImage,
    ctAs: item.ctAs,
    media:
      item?.carouselmedia?.gatsbyImageData ?? item?.carouselmedia?.file?.url,
  }));
  const [activeIndex, setActiveIndex] = useState(0);
  const handleItemClick = useCallback(
    index => setActiveIndex(index),
    [setActiveIndex],
  );
  const handleSelectChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const index = parseInt(event.target.value);
      setActiveIndex(index);
    },
    [setActiveIndex],
  );

  const imgAlignedLeft = imageAlignment === 'LEFT';
  const titles = items.map(item => item.title);
  const activeItem = items[activeIndex];

  return (
    <Container id={sectionId}>
      <div
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pb: [0, 4, 4],
        }}
      >
        <div
          sx={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: ['left', 'center'],
          }}
        >
          <Box sx={{ maxWidth: '960px', mb: 3 }}>
            <Flex
              sx={{
                justifyContent: 'center',
              }}
            >
              <Themed.h2
                sx={{
                  fontWeight: ['bold', null],
                  fontSize: [5, 6, null],
                  textAlign: 'center',
                  width: '100%',
                  margin: '0 auto',
                  mb: [3, 3, 3],
                }}
              >
                {heading}
              </Themed.h2>
            </Flex>
            {description && (
              <Themed.p
                sx={{
                  textAlign: ['center', 'left'],
                  mb: 0,
                }}
              >
                {description}
              </Themed.p>
            )}
          </Box>
        </div>
        <div
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            mt: [1, 5],
            flexDirection: imgAlignedLeft
              ? ['column', 'row-reverse']
              : ['column', 'row'],
            '> div + div': {
              ml: imgAlignedLeft ? [0, 0] : [0, 5],
              mr: imgAlignedLeft ? [0, '160px'] : [0, 0],
            },
          }}
        >
          {/* desktop */}
          <div
            sx={{
              display: ['none', 'flex'],
              flexDirection: 'column',
              flex: '1 1 auto',
            }}
          >
            {titles.map((key, index) => {
              const isActive = activeIndex === index;
              const handleClick = () => {
                handleItemClick(index);
              };
              return (
                <div
                  key={key}
                  onClick={handleClick}
                  sx={{
                    color: isActive ? 'text' : 'textMuted',
                    fontWeight: 'medium',
                    fontSize: [null, 3, 5, 8],
                    cursor: 'pointer',
                    pb: [null, 2, 2, 3],
                    pt: index === 0 ? 0 : [null, 2, 3, 3],
                    letterSpacing: '.23px',
                    position: 'relative',
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                    '&:before': {
                      content: '"•"',
                      width: '11px',
                      height: '11px',
                      visibility: isActive ? 'visible' : 'hidden',
                      mr: [null, 2, 2, 3],
                    },
                  }}
                >
                  {key}
                </div>
              );
            })}
          </div>

          {/* mobile */}
          <div
            sx={{
              display: ['flex', 'none'],
              alignItems: 'center',
              mb: 3,
              width: '100%',
              '> div': {
                width: '100%',
                '& svg': {
                  display: 'none',
                },
              },
            }}
          >
            <Select
              name="items"
              onChange={handleSelectChange}
              sx={{
                borderRadius: 2,
                color: 'textMuted',
                fontSize: 1,
                height: '50px',
                py: 2,
                px: 3,
                flex: 'auto',
                width: '100%',
              }}
            >
              {titles.map((title, index) => (
                <option key={title} value={index}>
                  {title}
                </option>
              ))}
            </Select>
            <ChevronDown
              sx={{
                width: '20px',
                ml: '-42px',
                path: { stroke: 'textMuted' },
                pointerEvents: 'none',
              }}
            />
          </div>

          <div
            sx={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: ['100%', '720px'],
              width: '100%',
            }}
          >
            <Themed.p
              sx={{
                mb: [2, 4, 4],
                order: [1, 0],
                mt: [3, 0],
                textAlign: 'left',
              }}
            >
              {activeItem.caption}
            </Themed.p>
            <ShowcaseTabsMedia {...activeItem} />
            {activeItem?.ctAs ? (
              <Flex
                sx={{
                  mt: [3, 4],
                  display: ['flex'],
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  '> button + button': {
                    ml: 4,
                  },
                }}
              >
                {activeItem.ctAs.map((cta, index) => (
                  <CallToAction key={cta.text} {...cta} />
                ))}
              </Flex>
            ) : null}
          </div>
        </div>
      </div>
    </Container>
  );
};

ShowcaseTabs.displayName = 'ShowcaseTabs';
export default ShowcaseTabs;
